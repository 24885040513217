import React from 'react';
import { Link } from 'gatsby';
import DateSpan from '../../fields/DateSpan';
import Image from '../../fields/Image';

const EventCard = (props) => {
	const data = props.data.node;
	const entity_data = data.r.entity_id;
	const media = entity_data.r.media;
	// entity_data.field_dates.end or start

	return (
		<Link
			to={data.r.entity_id.path.alias}
			className='flex flex-1 my-5 flex-col rounded-md shadow-lg overflow-hidden text-left'>
			<div className='flex-grow'>
				<div>
					{media && (
						<div className='flex-shrink-0'>
							<Image
								className={`h-48 w-full rounded-t-md object-cover ${
									props.previousEvent ? 'opacity-50' : ''
								}`}
								image={media.r.image}
								alt={entity_data.title}
								sizes='100w'
							/>
						</div>
					)}
					<div className='bg-white p-6 pb-0 flex flex-col justify-between'>
						<div className='flex-1'>
							<div className='block'>
								<div className='hidden md:block font-body uppercase max-w-max font-semibold px-2 py-1 text-darkgreen text-xs leading-4 bg-ap-green-light rounded-full'>
									{entity_data.r.field_audience.name}
								</div>
								{entity_data.title && (
									<h3 className='font-title md:my-6 text-xl leading-7 font-semibold text-gray-900'>
										{entity_data.title}
									</h3>
								)}
								{entity_data.summary && (
									<p className='hidden md:block font-body mt-3 text-base text-gray-800'>
										{entity_data.summary}
									</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='p-6 pt-8 items-end'>
				<DateSpan
					startDate={entity_data.field_dates.start}
					endDate={entity_data.field_dates.end}
					textClasses='font-body font-semibold text-xs text-ap-purple'
				/>
			</div>
		</Link>
	);
};

export default EventCard;
