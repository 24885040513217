import { useStaticQuery, graphql } from 'gatsby';

export const UseEventList = () => {
	const events = useStaticQuery(graphql`
		query EventGridList {
			events: allGroupContentLodgeGroupNodeEvent(
				filter: { relationships: { entity_id: { status: { eq: true } } } }
				sort: {
					order: DESC
					fields: relationships___entity_id___field_dates___value
				}
			) {
				edges {
					node {
						...eventFragment
					}
				}
			}
		}
	`);
	return events;
};
