import React, { useState } from 'react';
import { UseEventList } from './useEventList';

import EventCard from './EventCard';

const EventGridList = (props) => {
	const eventList = UseEventList();

	let eventDate = new Date();

	let endDate = new Date();

	const currentDate = new Date();

	const eventsPerLoad = 8;

	const [nextUpcoming, setNextUpcoming] = useState(eventsPerLoad);

	const handleMoreUpcomingEvents = () => {
		setNextUpcoming(nextUpcoming + eventsPerLoad);
	};

	const [nextPast, setNextPast] = useState(eventsPerLoad);

	const handleMorePastEvents = () => {
		setNextPast(nextPast + eventsPerLoad);
	};

	const oneDay = 1000 * 60 * 60 * 24;

	function getMidnight(day) {
		const date = new Date(day);
		date.setMilliseconds(999);
		date.setSeconds(59);
		date.setMinutes(59);
		date.setHours(23);
		return date;
	}

	/**
	 * All events in the CMS are returned here. We need to filter for our group and only
	 * events that have an end date of today or later.
	 */
	const upcomingEvents = eventList.events.edges.filter((item) => {
		// Check and see if the end date is null. If so there is no end date.
		if (item.node.r.entity_id.field_dates.end) {
			endDate = new Date(item.node.r.entity_id.field_dates.end);
		} else {
			eventDate = new Date(item.node.r.entity_id.field_dates.start);
			endDate = getMidnight(eventDate);
		}
		return (
			item.node.r.gid.drupal_internal__id.toString() ===
				process.env.GATSBY_GROUP_ID && endDate >= currentDate
		);
	});

	upcomingEvents.sort(function(a, b) {
		return (
			new Date(a.node.r.entity_id.field_dates.start) -
			new Date(b.node.r.entity_id.field_dates.start)
		);
	});

	const pastEvents = eventList.events.edges.filter((item) => {
		// Check and see if the end date is null. If so there is no end date.
		if (item.node.r.entity_id.field_dates.end) {
			endDate = new Date(item.node.r.entity_id.field_dates.end);
		} else {
			eventDate = new Date(item.node.r.entity_id.field_dates.start);
			endDate = getMidnight(eventDate);
		}
		return (
			item.node.r.gid.drupal_internal__id.toString() ===
				process.env.GATSBY_GROUP_ID && endDate < currentDate
		);
	});

	return (
		<div className='bg-white max-w-7xl mx-auto'>
			{props.type === 'page' && (
				<div>
					{upcomingEvents.length > 0 && (
						<div>
							<div className=''>
								<div className=''>
									<h2 className='font-title text-left text-3xl pb-6 pt-12 leading-9 font-extrabold sm:text-4xl'>
										Upcoming Events
									</h2>
								</div>
								<div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center md:gap-3'>
									{upcomingEvents.map((item) => {
										return (
											<EventCard
												data={item}
												format='short'
												key={item.node.drupal_internal__id}
											/>
										);
									})}
								</div>
							</div>
						</div>
					)}
					{upcomingEvents.length === 0 && (
						<div className=''>
							<div className=''>
								<div className='lg:pt-12 lg:pb-24'>
									<h2 className='font-title text-3xl pt-4 text-center font-extrabold sm:text-4xl'>
										There are currently no upcoming events.
									</h2>
									<div className='font-title text-3xl pt-4 pb-12 text-center font-extrabold sm:text-4xl'>
										Please check back soon!
									</div>
								</div>
							</div>
						</div>
					)}
					{pastEvents.length > 0 && (
						<div className=''>
							<div className=''>
								<div className=''>
									<h2 className='font-title text-left text-3xl pb-6 pt-12 leading-9 font-extrabold sm:text-4xl'>
										Previous Events
									</h2>
								</div>
								<div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center md:gap-3'>
									{pastEvents?.slice(0, nextPast)?.map((item) => {
										return (
											<EventCard
												data={item}
												format='short'
												previousEvent='true'
												key={item.node.drupal_internal__id}
											/>
										);
									})}
								</div>
								{nextPast < pastEvents?.length && (
									<button
										type='button'
										className='mt-10 inline-flex items-center px-5 py-3  text-base leading-6 font-medium rounded-md text-white bg-ap-purple hover:bg-ap-purple-dark focus:outline-none transition duration-150 ease-in-out'
										onClick={handleMorePastEvents}>
										Load More
									</button>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default EventGridList;
