import React from 'react';
import PropTypes from 'prop-types';

const DateSpan = (props) => {
	const startDate = new Intl.DateTimeFormat('en-US', {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	}).format(new Date(props.startDate));
	const startTime = new Intl.DateTimeFormat('en-US', {
		hour: 'numeric',
		minute: '2-digit',
	}).format(new Date(props.startDate));
	let endDate = null;
	let endTime = null;
	if (props.endDate) {
		endDate = new Intl.DateTimeFormat('en-US', {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		}).format(new Date(props.endDate));
		endTime = new Intl.DateTimeFormat('en-US', {
			hour: 'numeric',
			minute: '2-digit',
		}).format(new Date(props.endDate));
	}
	const multiDate = (startDate !== endDate && endDate !== null) ? true : false;
	const multiDateDisplay = multiDate ? 'w-full' : 'w-1/2';

	const displayFormat = props.displayFormat === 'col' ? 'flex-col' : 'flex-row';

	return (
		<div className={`flex ${displayFormat} justify-center uppercase`}>
			<div className={`flex flex-row justify-start content-start ${multiDateDisplay} lg:w-full mb-1 md:mb-0`}>
				<div className='self-center mr-1 lg:mr-2'>
					<svg
						width='18'
						height='20'
						viewBox='0 0 18 20'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M15.2222 2.77777H2.77778C1.79594 2.77777 1 3.57371 1 4.55555V17C1 17.9818 1.79594 18.7778 2.77778 18.7778H15.2222C16.2041 18.7778 17 17.9818 17 17V4.55555C17 3.57371 16.2041 2.77777 15.2222 2.77777Z'
							stroke='#1F2937'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M12.5557 1V4.55556'
							stroke='#1F2937'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M5.44434 1V4.55556'
							stroke='#1F2937'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M1 8.11111H17'
							stroke='#1F2937'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</div>
				{!multiDate && (
					<div className={`self-center ${props.textClasses}`}>{startDate}</div>
				)}
				{multiDate && (
					<div className={`self-center ${props.textClasses}`}>
						{startDate} - {endDate}
					</div>
				)}
			</div>
			{!multiDate && (
				<div className='flex justify-start w-1/2 lg:w-full'>
					<div className='self-center mr-1 lg:mr-2'>
						<svg
							width='18'
							height='18'
							viewBox='0 0 18 18'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z'
								stroke='#1F2937'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M9 4.2V9L12.2 10.6'
								stroke='#1F2937'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</div>
					<div className={`self-center ${props.textClasses}`}>
						{startTime} {endDate ? '- ' + endTime : ''}
					</div>
				</div>
			)}
		</div>
	);
};

DateSpan.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
};

export default DateSpan;
